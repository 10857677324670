<template>
    <div>
        <page-navigate></page-navigate>
        <div :class="[$t('common.requestLang'), $t('common.type')]">
            <div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
                <section class="section1">
                    <h1 :class="[$t('common.fontFamilyTitle')]" v-html="$t('win3.title')">
                    </h1>
                    <div class="mobile" v-html="$t('win3.title2')">
                    </div>
                    <div class="subtitle" :class="[$t('common.fontFamilyTitle')]">
                        <div class="subtitle-line-left"></div>
                        <img class="title-icon" src="@/assets/image/win/title_icon.svg" v-if="$t('common.type') == 'en'"
                            alt="" />
                        {{ $t('win3.巴萨传奇再启') }}
                        <img class="title-icon" src="@/assets/image/win/title_icon.svg" alt="" />
                        {{ $t('win3.成就交易之王') }}
                        <div class="subtitle-line-right"></div>
                    </div>
                    <div class="section1-info-times">
                        {{ $t('win3.大赛时间') }}
                    </div>
                    <div class="section1-info-time">
                        <div class="section1-info-time-label pc" v-html="$t('win3.距开赛')"></div>
                        <div class="section1-info-opentime">
                            <div class="section1-info-time-label mobile " v-html="$t('win3.距开赛')"></div>
                            <span id="day1" class="number-box">0</span>
                            <span id="day2" class="number-box">0</span>
                            {{ $t('win3.天') }}
                            <span id="hour1" class="number-box">0</span>
                            <span id="hour2" class="number-box">0</span>
                            {{ $t('win3.时') }}
                            <span id="minute1" class="number-box">0</span>
                            <span id="minute2" class="number-box">0</span>
                            {{ $t('win3.分') }}
                            <span id="second1" class="number-box">0</span>
                            <span id="second2" class="number-box">0</span>
                            {{ $t('win3.秒') }}
                        </div>
                        <div class="buttons">
                            <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
                        </div>
                    </div>
                </section>
                <section class="section2f">
                    <div class="section2-header">
                        <p class="section2-card-label" :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.现金奖池') }}</p>
                        <p class="section2-card-value">$312,000</p>
                    </div>

                    <div class="section2f-card-box">
                        <div class="section2f-card">
                            <img class="section2f-card-icon" src="@/assets/image/win/section2_icon.svg" alt="" />
                            <p class="p1 " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.一键跟单') }}</p>
                            <p class="p2">{{ $t('win3.免费复制冠军收益') }}</p>
                        </div>
                        <div class="section2f-card">
                            <img class="section2f-card-icon" src="@/assets/image/win/section2_icon.svg" alt="" />
                            <p class="p1 " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.公开透明') }}</p>
                            <p class="p2">{{ $t('win3.观摩冠军交易技巧') }}</p>
                        </div>
                        <div class="section2f-card">
                            <img class="section2f-card-icon" src="@/assets/image/win/section2_icon.svg" alt="" />
                            <p class="p1 " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.推荐有礼') }}</p>
                            <p class="p2">{{ $t('win3.推荐有奖上不封顶') }}</p>
                        </div>
                    </div>
                </section>
                <section class="section2">
                    <img class="section-bg" src="@/assets/image/win/section2_bg.png" alt="" />
                    <div class="buttons-group">
                        <a id="section2button1" @click="handleChange(true)" href="javascript:;"
                            class="group-button-left " :class="{ 'group-button-active': section2ContentFlag }">{{
                                $t('win3.巴萨梦之队') }}</a>
                        <a id="section2button2" @click="handleChange(false)" href="javascript:;"
                            class="group-button-right" :class="{ 'group-button-active': !section2ContentFlag }">{{
                                $t('win3.拉玛西亚组') }}</a>
                    </div>
                    <div v-if="section2ContentFlag" class="section2-content">
                        <div class="pc">
                            <div class="reward-list-pc reward">
                                <div class="reward-item reward-item-3">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.第四至五名') }}</p>
                                        <p class="p2">$5,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-2">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.亚军') }}</p>
                                        <p class="p2">$30,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-1">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.冠军') }}</p>
                                        <p class="p2">{{ $t('win3.$1,000,000交易账号或$200,000现金') }}</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-2">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.季军') }}</p>
                                        <p class="p2">$10,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-3">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.第六至十名') }}</p>
                                        <p class="p2">$3,000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="reward-list">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-1">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.冠军') }}</p>
                                                <p class="p2">{{ $t('win3.$1,000,000交易账号或$200,000现金') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-2">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.亚军') }}</p>
                                                <p class="p2">$30,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-2">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.季军') }}</p>
                                                <p class="p2">$10,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-3">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.第四至五名') }}</p>
                                                <p class="p2">$5,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-3">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.第六至十名') }}</p>
                                                <p class="p2">$3,000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!section2ContentFlag" class="section2-content ">
                        <div class="pc">
                            <div class="reward-list-pc reward">
                                <div class="reward-item reward-item-3">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.第四至五名') }}</p>
                                        <p class="p2">$2,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-2">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.亚军') }}</p>
                                        <p class="p2">$5,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-1">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.冠军') }}</p>
                                        <p class="p2">$30,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-2">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.季军') }}</p>
                                        <p class="p2">$3,000</p>
                                    </div>
                                </div>
                                <div class="reward-item reward-item-3">
                                    <div class="reward-item-content">
                                        <p class="p1">{{ $t('win3.第六至十名') }}</p>
                                        <p class="p2">$1,000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="reward-list2">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-1">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.冠军') }}</p>
                                                <p class="p2">$30,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-2">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.亚军') }}</p>
                                                <p class="p2">$5,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-2">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.季军') }}</p>
                                                <p class="p2">$3,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-3">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.第四至五名') }}</p>
                                                <p class="p2">$2,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="reward-item reward-item-3">
                                            <div class="reward-item-content">
                                                <p class="p1">{{ $t('win3.第六至十名') }}</p>
                                                <p class="p2">$1,000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination swiper-pagination2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section3">
                    <h3 class="tab-title " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.大赛推荐官') }}</h3>
                    <div class="section3-content">
                        <p class="p1">{{ $t('win3.推荐奖励') }}</p>
                        <div class="section3-group">
                            <div class="section3-group-card section3-group-card1"
                                :class="{ 'flex-column': $t('common.type') == 'en' }">
                                <div class="section3-group-card-left" v-html="$t('win3.巴萨梦之队HTML')">

                                </div>
                                <div class="section3-group-card-right">
                                    <p class="section3-group-card-value">
                                        $300<span class="unit">{{ $t('win3./人') }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="section3-group-card section3-group-card2"
                                :class="{ 'flex-column': $t('common.type') == 'en' }">
                                <div class="section3-group-card-left" v-html="$t('win3.拉玛西亚组HTML')">

                                </div>
                                <div class="section3-group-card-right">
                                    <p class="section3-group-card-value">
                                        $30<span class="unit">{{ $t('win3./人') }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p class="p1 mt-46">{{ $t('win3.推荐流程') }}</p>
                        <div class="section3-card">
                            <div class="section3-card-steps">
                                <div class="section3-card-step">
                                    <img class="step-img" src="@/assets/image/win/step1.png" alt="" />
                                    <div class="section3-card-step-value">{{ $t('win3.推荐好友') }}</div>
                                </div>
                                <img class="right-img" src="@/assets/image/win/section3_right.png" alt="" />
                                <div class="section3-card-step">
                                    <img class="step-img" src="@/assets/image/win/step2.png" alt="" />
                                    <div class="section3-card-step-value">{{ $t('win3.好友参赛') }}</div>
                                </div>
                                <img class="right-img" src="@/assets/image/win/section3_right.png" alt="" />
                                <div class="section3-card-step">
                                    <img class="step-img" src="@/assets/image/win/step3.png" alt="" />
                                    <div class="section3-card-step-value">{{ $t('win3.赢得奖金') }}</div>
                                </div>
                            </div>
                            <div class="times">
                                {{ $t('win3.活动时间') }}
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section4">
                    <h3 class="tab-title tab-title-red " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.实时赛况') }}
                    </h3>
                    <div class="buttons-group mt-56">
                        <a id="section4button1" @click="handleChangeBang(true)" href="javascript:;"
                            class="group-button-left " :class="{ 'group-button-active': section4ContentFlag }">{{
                                $t('win3.巴萨梦之队') }}</a>
                        <a id="section4button2" @click="handleChangeBang(false)" href="javascript:;"
                            class="group-button-right" :class="{ 'group-button-active': !section4ContentFlag }">{{
                                $t('win3.拉玛西亚组') }}</a>
                    </div>
                    <div v-show="section4ContentFlag" class="section4-content">
                        <div class="section4-table">
                            <div class="th">
                                <div class="td">{{ $t('win3.排名') }}</div>
                                <div class="td">{{ $t('win3.昵称') }}</div>
                                <div class="td">{{ $t('win3.服务器') }}</div>
                                <div class="td">{{ $t('win3.账号') }}</div>
                                <div class="td">{{ $t('win3.收益额') }}</div>
                                <div class="td">{{ $t('win3.奖金') }}</div>
                                <div class="td">{{ $t('win3.跟单') }}</div>
                            </div>
                            <div class="tr" v-for="item in datalist">
                                <div class="td">{{ item.Rank }}</div>
                                <div class="td">{{ item.Nickname }}</div>
                                <div class="td">{{ item.Server }}</div>
                                <div class="td">{{ item.AccountNumber }}</div>
                                <div class="td">{{ item.Profit }}</div>
                                <div class="td">{{ $t('win3.$1,000,000交易账号或$200,000现金') }}</div>
                                <div class="td"><a :href="signupUrl" class="button-text" target="blank">{{ $t('win3.跟单')
                                        }}</a></div>
                            </div>
                            <div v-if="datalist.length == 0" class="no-data">
                                {{ $t('win3.暂未开赛') }}
                            </div>
                        </div>
                    </div>
                    <div v-show="!section4ContentFlag" class="section4-content">
                        <div class="section4-table new-table">
                            <div class="th">
                                <div class="td">{{ $t('win3.排名') }}</div>
                                <div class="td">{{ $t('win3.昵称') }}</div>
                                <div class="td">{{ $t('win3.服务器') }}</div>
                                <div class="td">{{ $t('win3.账号') }}</div>
                                <div class="td">{{ $t('win3.收益率') }}</div>
                                <div class="td">{{ $t('win3.奖金') }}</div>
                                <div class="td">{{ $t('win3.跟单') }}</div>
                            </div>
                            <div class="tr" v-for="item in datalist2">
                                <div class="td">{{ item.Rank }}</div>
                                <div class="td">{{ item.Nickname }}</div>
                                <div class="td">{{ item.Server }}</div>
                                <div class="td">{{ item.AccountNumber }}</div>
                                <div class="td">{{ item.ProfitRate }}</div>
                                <div class="td">{{ item.Prize }}</div>
                                <div class="td"><a :href="signupUrl" class="button-text" target="blank">{{ $t('win3.跟单')
                                        }}</a></div>
                            </div>
                            <div v-if="datalist2.length == 0" class="no-data">
                                {{ $t('win3.暂未开赛') }}
                            </div>
                        </div>
                    </div>
                    <div class="section4-tips" v-html="$t('win3.该页面仅展示收益表现排名前10的交易账号')">

                    </div>
                </section>

                <section class="section5">
                    <h3 class="tab-title " :class="[$t('common.fontFamilyTitle')]"> {{ $t('win3.赛事进程') }}</h3>
                    <div class="section5-time-line">
                        <div class="section5-lines">
                            <div class="section5-line-item">
                                <p class="p1">2025.3.1</p>
                                <p class="p2">{{ $t('win3.比赛开始') }}</p>
                            </div>
                            <div class="section5-line-item">
                                <p class="p1">2025.5.23</p>
                                <p class="p2">{{ $t('win3.推荐结束') }}</p>
                            </div>
                            <div class="section5-line-item">
                                <p class="p1">2025.5.30</p>
                                <p class="p2">{{ $t('win3.比赛结束') }}</p>
                            </div>
                            <div class="section5-line-item">
                                <p class="p1">2025</p>
                                <p class="p2">{{ $t('win3.颁奖典礼') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="buttons mt-56">
                        <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
                    </div>
                </section>
                <section class="section6">
                    <div class="tab-btns">
                        <div id="section6button1" @click="handleChangeZu(true)" class="button-title  "
                            :class="[$t('common.fontFamilyTitle'), { 'button-title-active': section6ContentFlag }]">
                            {{ $t('win3.赛组规则') }}
                        </div>
                        <div class="section6-line"></div>
                        <div id="section6button2" @click="handleChangeZu(false)" class="button-title "
                            :class="[$t('common.fontFamilyTitle'), { 'button-title-active': !section6ContentFlag }]">
                            {{ $t('win3.参赛须知') }}
                        </div>
                    </div>
                    <div v-show="section6ContentFlag" class="section6-content">
                        <table class="section6-table">
                            <!-- 表格头部 (可选) -->
                            <thead>
                                <tr>
                                    <th>{{ $t('win3.类别') }}</th>
                                    <th>{{ $t('win3.巴萨梦之队') }}</th>
                                    <th>{{ $t('win3.拉玛西亚组') }}</th>
                                </tr>
                            </thead>
                            <!-- 表格主体 -->
                            <tbody>
                                <tr>
                                    <td>{{ $t('win3.账号要求') }}</td>
                                    <td>{{ $t('win3.新交易账号，且$10,000≤账号余额≤$200,000') }}</td>
                                    <td>{{ $t('win3.新交易账号，且账号余额大于$500') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('win3.排名规则') }}</td>
                                    <td>{{ $t('win3.以净盈利额排名为评判标准') }}</td>
                                    <td>
                                        {{ $t('win3.以收益率排名为评判标准') }}<br />
                                        {{ $t('win3.收益率=期末净值/账号累积入金') }}

                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('win3.持仓限制') }}</td>
                                    <td>{{ $t('win3.每个参赛账号最多持有100手仓位') }}</td>
                                    <td>{{ $t('win3.无持仓限制') }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="section6-step-box">
                            <h3 class="section6-step-title " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.参赛流程')
                                }}
                            </h3>
                            <div class="section6-step-content">
                                <div class="section6-step-item section6-item-1">
                                    <h5>{{ $t('win3.注册开户') }}</h5>
                                    <p class="p2">{{ $t('win3.注册并开设新交易账号') }}</p>
                                </div>
                                <img class="section6-right" src="@/assets/image/win/section6_right.png" alt="" />
                                <div class="section6-step-item section6-item-2">
                                    <h5>{{ $t('win3.报名参赛') }}</h5>
                                    <p class="p2">{{ $t('win3.在用户中心报名参赛') }}</p>
                                </div>
                                <img class="section6-right" src="@/assets/image/win/section6_right.png" alt="" />
                                <div class="section6-step-item section6-item-3">
                                    <h5>{{ $t('win3.参赛成功') }}</h5>
                                    <p class="p2">{{ $t('win3.参赛成功查看实时赛况') }}</p>
                                </div>
                            </div>
                            <div class="buttons">
                                <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
                            </div>
                        </div>
                    </div>
                    <div v-show="!section6ContentFlag" class="section6-content">
                        <div class="section6-list">
                            <div class="section6-list-title">{{ $t('win3.参赛条款') }}</div>
                            <div class="section6-list-item" v-for="(item, index) in $t('win3.section6List')"
                                :key="index">
                                <div class="section6-list-item-title">{{ item.t }}</div>
                                <div class="section6-list-item-content">
                                    <p v-for="(p, i) in item.c" :key="i">{{ p }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="qa-list mt-12">
                            <div class="qa-list-title">{{ $t('win3.常见问题') }}</div>
                            <div class="qa-list-item" v-for="(item, index) in $t('win3.qaList')" :key="index">
                                <div class="qa-list-item-title">{{ item.t }}</div>
                                <div class="qa-list-item-content">
                                    <p v-for="(p, i) in item.c" :key="i">{{ p }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section8">
                    <h3 class="tab-title " :class="[$t('common.fontFamilyTitle')]">{{ $t('win3.往届回顾') }}</h3>
                    <div class="pc">
                        <div class="section8-content">
                            <a href="/win" class="section8-item">
                                <img class="section8-item-img" src="@/assets/image/win/section8_1.jpg" alt="" />
                                <h4>{{ $t('win3.第一届交易大赛颁奖典礼') }}</h4>
                            </a>
                            <a href="/win" class="section8-item">
                                <img class="section8-item-img" src="@/assets/image/win/section8_2.jpg" alt="" />
                                <h4>{{ $t('win3.第一届交易大赛高光时刻') }}</h4>
                            </a>
                            <a href="/win" class="section8-item">
                                <img class="section8-item-img" src="@/assets/image/win/section8_3.jpg" alt="" />
                                <h4>{{ $t('win3.第一届交易大赛巅峰对决') }}</h4>
                            </a>
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="section8-list-box">
                            <div class="section8-list">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <a href="/win" class="section8-item">
                                            <img class="section8-item-img" src="@/assets/image/win/section8_1.jpg"
                                                alt="" />
                                            <h4>{{ $t('win3.第一届交易大赛颁奖典礼') }}</h4>
                                        </a>
                                    </div>
                                    <div class="swiper-slide">
                                        <a href="/win" class="section8-item">
                                            <img class="section8-item-img" src="@/assets/image/win/section8_2.jpg"
                                                alt="" />
                                            <h4>{{ $t('win3.第一届交易大赛高光时刻') }}</h4>
                                        </a>
                                    </div>
                                    <div class="swiper-slide">
                                        <a href="/win" class="section8-item">
                                            <img class="section8-item-img" src="@/assets/image/win/section8_3.jpg"
                                                alt="" />
                                            <h4>{{ $t('win3.第一届交易大赛巅峰对决') }}</h4>
                                        </a>
                                    </div>
                                </div>
                                <div class="swiper-button-prev swiper-news-prev mobile"></div>
                                <div class="swiper-button-next swiper-news-next mobile"></div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons mt-56">
                        <a href="/win" class="section-button">{{ $t('win3.查看更多') }}</a>
                    </div>
                </section>
            </div>
        </div>
        <page-footer></page-footer>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
// 获取2位数组
function splitTensAndOnes(num) {
    // 确保输入的是非负整数
    if (!Number.isInteger(num) || num < 0) {
        throw new Error("请输入一个非负整数");
    }
    // 将数字转换为字符串，以便容易地访问每一位
    let strNum = String(num);
    // 如果数字大于等于100，我们只取最后两位
    if (num >= 100) {
        strNum = strNum.slice(-2);
    }
    // 补齐到两位，如果需要的话
    if (strNum.length < 2) {
        strNum = "0" + strNum;
    }
    // 分割字符串为数组并转换回数字
    const result = Array.from(strNum, Number);

    return result;
}

var startDate = new Date("2025-03-01T00:00:00").getTime();

function updateCountdown() {
    var now = new Date().getTime();
    var timeDifference = startDate - now;

    var days = splitTensAndOnes(
        Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    );
    var hours = splitTensAndOnes(
        Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    var minutes = splitTensAndOnes(
        Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    );
    var seconds = splitTensAndOnes(
        Math.floor((timeDifference % (1000 * 60)) / 1000)
    );
    document.getElementById("day1").textContent = days[0];
    document.getElementById("day2").textContent = days[1];
    document.getElementById("hour1").textContent = hours[0];
    document.getElementById("hour2").textContent = hours[1];
    document.getElementById("minute1").textContent = minutes[0];
    document.getElementById("minute2").textContent = minutes[1];
    document.getElementById("second1").textContent = seconds[0];
    document.getElementById("second2").textContent = seconds[1];
}
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            section2ContentFlag: true,
            section4ContentFlag: true,
            section6ContentFlag: true,
            datalist: [
                // {
                //   Rank: "01",
                //   Nickname: "Tra**ing",
                //   Server: "MT4-Live01",
                //   AccountNumber: "102345",
                //   Profit: "88,888,999",
                //   Prize: "$1,000,000交易账号或$200,000",
                // },
                // {
                //   Rank: "02",
                //   Nickname: "Cry**ter",
                //   Server: "MT4-Live01",
                //   AccountNumber: "876542",
                //   Profit: "8,888,999",
                //   Prize: "$30,000",
                // },
                // {
                //   Rank: "03",
                //   Nickname: "For**ard",
                //   Server: "MT4-Live03",
                //   AccountNumber: "364920",
                //   Profit: "888,999",
                //   Prize: "$10,000",
                // },
                // {
                //   Rank: "04",
                //   Nickname: "Mar**uru",
                //   Server: "MT4-Live04",
                //   AccountNumber: "509283",
                //   Profit: "88,999",
                //   Prize: "$5,000",
                // },
                // {
                //   Rank: "05",
                //   Nickname: "Pro**ker",
                //   Server: "MT4-Live04",
                //   AccountNumber: "132476",
                //   Profit: "88,999",
                //   Prize: "$5,000",
                // },
                // {
                //   Rank: "06",
                //   Nickname: "Gol**ter",
                //   Server: "MT4-Live06",
                //   AccountNumber: "248501",
                //   Profit: "88,999",
                //   Prize: "$3,000",
                // },
                // {
                //   Rank: "07",
                //   Nickname: "FXS**orm",
                //   Server: "MT4-Live06",
                //   AccountNumber: "793164",
                //   Profit: "88,999",
                //   Prize: "$3,000",
                // },
                // {
                //   Rank: "08",
                //   Nickname: "Tra**ick",
                //   Server: "MT4-Live08",
                //   AccountNumber: "984237",
                //   Profit: "88,999",
                //   Prize: "$3,000",
                // },
                // {
                //   Rank: "09",
                //   Nickname: "Dol**der",
                //   Server: "MT4-Live08",
                //   AccountNumber: "550492",
                //   Profit: "88,999",
                //   Prize: "$3,000",
                // },
                // {
                //   Rank: "10",
                //   Nickname: "Bul**ion",
                //   Server: "MT4-Live08",
                //   AccountNumber: "678395",
                //   Profit: "88,999",
                //   Prize: "$3,000",
                // },
            ],

            datalist2: [
                // {
                //   Rank: "01",
                //   Nickname: "Tra**ing",
                //   Server: "MT4-Live01",
                //   AccountNumber: "102345",
                //   ProfitRate: "+980%",
                //   Prize: "$30,000",
                // },
                // {
                //   Rank: "02",
                //   Nickname: "Cry**ter",
                //   Server: "MT5-Live02",
                //   AccountNumber: "876542",
                //   ProfitRate: "+780%",
                //   Prize: "$5,000",
                // },
                // {
                //   Rank: "03",
                //   Nickname: "For**ard",
                //   Server: "MT4-Live03",
                //   AccountNumber: "364920",
                //   ProfitRate: "+580%",
                //   Prize: "$3,000",
                // },
                // {
                //   Rank: "04",
                //   Nickname: "Mar**uru",
                //   Server: "MT5-Live04",
                //   AccountNumber: "509283",
                //   ProfitRate: "+480%",
                //   Prize: "$2,000",
                // },
                // {
                //   Rank: "05",
                //   Nickname: "Pro**ker",
                //   Server: "MT5-Live04",
                //   AccountNumber: "132476",
                //   ProfitRate: "+380%",
                //   Prize: "$2,000",
                // },
                // {
                //   Rank: "06",
                //   Nickname: "Gol**ter",
                //   Server: "MT5-Live04",
                //   AccountNumber: "248501",
                //   ProfitRate: "+280%",
                //   Prize: "$1,000",
                // },
                // {
                //   Rank: "07",
                //   Nickname: "FXS**orm",
                //   Server: "MT5-Live07",
                //   AccountNumber: "793164",
                //   ProfitRate: "+180%",
                //   Prize: "$1,000",
                // },
                // {
                //   Rank: "08",
                //   Nickname: "Tra**ick",
                //   Server: "MT5-Live08",
                //   AccountNumber: "984237",
                //   ProfitRate: "+150%",
                //   Prize: "$1,000",
                // },
                // {
                //   Rank: "09",
                //   Nickname: "Dol**der",
                //   Server: "MT5-Live09",
                //   AccountNumber: "550492",
                //   ProfitRate: "+120%",
                //   Prize: "$1,000",
                // },
                // {
                //   Rank: "10",
                //   Nickname: "Bul**ion",
                //   Server: "MT5-Live09",
                //   AccountNumber: "678395",
                //   ProfitRate: "+80%",
                //   Prize: "$1,000",
                // },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'signupUrl',
        ])
    },
    methods: {
        handleChange(flag) {
            this.section2ContentFlag = flag
            if (flag) {
                this.$nextTick(() => {
                    this.swiperreward = new Swiper(".reward-list", {
                        loop: true, // 循环模式
                        autoplay: {
                            delay: 6000, // 自动切换的时间间隔（毫秒）
                            disableOnInteraction: false, // 用户操作后是否停止自动播放
                        },
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true,
                        },
                        slidesPerView: 1, // 每次显示的幻灯片数量
                        spaceBetween: 10, // 幻灯片之间的间距
                    });
                })
            } else {
                this.$nextTick(() => {
                    this.swiperSwiper2 = new Swiper(".reward-list2", {
                        loop: true, // 循环模式
                        autoplay: {
                            delay: 6000, // 自动切换的时间间隔（毫秒）
                            disableOnInteraction: false, // 用户操作后是否停止自动播放
                        },
                        pagination: {
                            el: ".swiper-pagination2",
                            clickable: true,
                        },
                        slidesPerView: 1, // 每次显示的幻灯片数量
                        spaceBetween: 10, // 幻灯片之间的间距
                    });
                })
            }
        },
        handleChangeBang(flag) {
            this.section4ContentFlag = flag
        },

        handleChangeZu(flag) {
            this.section6ContentFlag = flag
        }
    },
    mounted() {
        // 获取所有 qa-list-item 元素
        const items = document.querySelectorAll(".qa-list-item");

        // 遍历所有 qa-list-item 并为它们添加点击事件监听器
        items.forEach((item) => {
            item.addEventListener("click", function () {
                // 如果当前点击的是已经拥有 active 类的元素，则移除它
                if (item.classList.contains("active")) {
                    item.classList.remove("active");
                } else {
                    // 否则先移除所有 qa-list-item 的 active 类，然后给当前点击的元素添加 active 类
                    items.forEach((i) => i.classList.remove("active"));
                    item.classList.add("active");
                }
            });
        });
        updateCountdown();

        setInterval(() => {
            updateCountdown();
        }, 1000);

        // 初始化 Swiper
        this.swipersection8 = new Swiper(".section8-list", {
            loop: true, // 循环模式
            autoplay: {
                delay: 6000, // 自动切换的时间间隔（毫秒）
                disableOnInteraction: false, // 用户操作后是否停止自动播放
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            slidesPerView: 1, // 每次显示的幻灯片数量
            spaceBetween: 10, // 幻灯片之间的间距
        });

        this.swiperreward = new Swiper(".reward-list", {
            loop: true, // 循环模式
            autoplay: {
                delay: 6000, // 自动切换的时间间隔（毫秒）
                disableOnInteraction: false, // 用户操作后是否停止自动播放
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            slidesPerView: 1, // 每次显示的幻灯片数量
            spaceBetween: 10, // 幻灯片之间的间距
        });
        this.swiperSwiper2 = new Swiper(".reward-list2", {
            loop: true, // 循环模式
            autoplay: {
                delay: 6000, // 自动切换的时间间隔（毫秒）
                disableOnInteraction: false, // 用户操作后是否停止自动播放
            },
            pagination: {
                el: ".swiper-pagination .swiper-pagination2",
                clickable: true,
            },
            slidesPerView: 1, // 每次显示的幻灯片数量
            spaceBetween: 10, // 幻灯片之间的间距
        });
    }
}
</script>

<style lang="scss" scoped>
::v-deep .swiper-pagination {
    transform: translateY(80rem) !important;
}

::v-deep .swiper-pagination-bullet {
    background: #a52933 !important;
    opacity: 0.5 !important;
}

::v-deep .swiper-pagination-bullet-active {
    background: #a52933 !important;
    opacity: 1 !important;
}

@import url("../assets/css/rem.css");

@font-face {
    font-family: "Financier Display";
    src: url("../assets/font/FinancierDisplay-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Financier Display Regular";
    src: url("../assets/font/FinancierDisplay-Regular.otf") format("opentype");
}

.flex-column {
    flex-direction: column;
}

.flex-column .section3-group-card-left,
.flex-column .section3-group-card-right {
    width: 100%;
}

.en_US .en-font-family {
    font-family: "Financier Display Regular" !important;
}

.en_US .en-font-family-title {
    font-family: "Financier Display" !important;
}

.en_US .reward-item .p1 {
    font-family: "Financier Display" !important;
}

.en_US .section-button,
.en_US .buttons-group {
    font-family: "Financier Display" !important;
}

.en .en-font-family {
    font-family: "Financier Display Regular" !important;
}

.en .en-font-family-title {
    font-family: "Financier Display" !important;
}

.en .reward-item .p1 {
    font-family: "Financier Display" !important;
}

.en .section-button,
.en .buttons-group {
    font-family: "Financier Display" !important;
}

.section1 {
    height: 900rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 70rem;
    justify-content: flex-end;
    /* align-items: flex-end; */
    background: url("../assets/image/win/section1_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.en .section1 {
    padding-bottom: 100rem;
}

::v-deep .section1 h1 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    color: #e9d0b5;
    text-align: center;
    text-shadow: 0px 5.354rem 3.569rem rgba(0, 0, 0, 0.45);
    font-size: 68rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1em;
    letter-spacing: 0.875rem;
    gap: 8rem;
}



.section1 h1 .ebc {
    font-size: 96rem;
    font-family: "Financier Display";
    line-height: 1em;
}

.section1 h1 .text {
    margin-top: 12rem;
}

.subtitle {
    margin-top: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18rem;
    color: #fff;
    text-align: center;
    font-size: 36rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0.48rem;
}

.en .subtitle {
    font-size: 24rem;
}

.title-icon {
    width: 30rem;
    height: 30rem;
}

.subtitle-line-left {
    height: 1px;
    width: 400rem;
    background: linear-gradient(to left,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0));
}

.subtitle .icon-Vector {
    font-size: 24rem;
}

.subtitle-line-right {
    height: 1px;
    width: 400rem;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0));
}

.bg {
    background: url("../assets/image/win/bg.png");
    background-size: 100% auto;
    background-repeat: repeat;
}

.section1-info-time {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 34rem;
    margin-top: 22rem;
}

.section1-info-time-label {
    color: #fff;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18rem;
    /* 128.571% */
    margin-right: -18rem;
}

.section1-info-times {
    text-align: center;
    color: #b2afad;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    margin-top: 58rem;
}

.en .section1-info-times {
    margin-top: 36rem;
}

.section1-info-opentime {
    display: flex;
    align-items: center;
    gap: 12rem;
    color: #fff;
    text-align: center;
    font-size: 24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 24rem;
}

.section-button {
    padding: 20rem 36rem;
    border-radius: 5rem;
    background: #a52933;
    color: #e6ddcf;
    text-align: center;
    font-size: 24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    transition: all 0.3s;
}

.section-button:hover {
    color: #fff;
}

.section2,
.section4,
.section6 {
    position: relative;
    padding: 90rem 0 100rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg,
            rgb(255, 255, 255, 0.95) 0%,
            rgba(178, 175, 173, 0.99) 100%),
        url("../assets/image/win/bg.png");
    background-size: 100% auto;
    background-repeat: repeat;
}

.section-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
}

.section8 {
    position: relative;
    padding: 90rem 0 100rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg,
            rgba(93, 35, 51, 0.95) 0%,
            rgba(43, 10, 10, 0.99) 100%),
        url("../assets/image/win/bg.png");
    background-size: 100% auto;
    background-repeat: repeat;
}

/* .section2 {
  padding-bottom: 0;
} */
.section2-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem;
}

.section2-card-label {
    color: #fff;
    text-align: center;
    font-size: 30rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.6rem;
}

.section2-card-value {
    text-align: center;
    font-family: "Financier Display";
    font-size: 170rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: 6.8rem;
    background: linear-gradient(180deg, #fff 20.5%, rgba(153, 153, 153, 0) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.reward-list-pc {
    display: flex;
    justify-content: center;
    gap: 10rem;
}

.reward-item {
    display: flex;
    align-items: flex-end;
    width: 282rem;
    height: 442rem;

}

.reward-item-1 {
    background-image: url("../assets/image/win/jiangbeipc1.png");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.reward-item-2 {
    width: 262rem;
    background-image: url("../assets/image/win/jiangbeipc2.png");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.reward-item-3 {
    width: 211rem;
    background-image: url("../assets/image/win/jiangbeipc3.png");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.reward-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12rem;
    width: 100%;
    padding: 0 20rem;
    height: 151rem;
}

.reward-item-2 .reward-item-content {
    height: 134rem;
}

.reward-item-3 .reward-item-content {
    height: 120rem;
}

.reward-item .p1 {
    color: #5d2333;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
}

.en .reward-item .p1 {
    font-size: 26rem;
}

.reward-item .p2 {
    display: block;
    color: #5d2333;
    text-align: center;
    font-size: 21rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
}


.en .reward-item .p2 {
    font-size: 18rem;
    padding: 0 24rem;
}

.section2f {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 90rem 0 30rem;
    background: url("../assets/image/win/section2f_bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.section2f-card-box {
    display: flex;
    justify-content: center;
}

.section2f-card {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 20rem;
    height: 348rem;
    width: 348rem;
    background: url("../assets/image/win/section2f_item_bg.png");
    background-size: cover;
}

.section2f-card-label {
    color: #6a6967;
    font-size: 19rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22rem;
}

.section2f-card-value {
    color: #a52933;
    font-family: "Financier Display";
    font-size: 105rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 3.15rem;
}

.section2f-card-icon {
    width: 27rem;
    height: 27rem;
    margin-top: 92rem;
}

.section2f-card .p1 {
    color: #fff;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}

.en .section2f-card .p1 {
    font-size: 29rem;
}

.section2f-card .p2 {
    color: #c9c7c6;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
}

.tab-title {
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-size: 48rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.96rem;
}

.tab-title-red {
    color: #5d2333;
}

.reward {
    display: flex;
    gap: 12rem;

    width: 1450rem;
    height: 444rem;
    margin-top: 78rem;
}

.hidden {
    display: none;
    visibility: hidden;
}

.number-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 41rem;
    height: 59rem;
    border-radius: 5rem;
    border: 1px solid #5d2333;
    background: linear-gradient(180deg, #5d2333 50.8%, #1c1c1c 51.7%);
    color: #fff;
    text-align: center;
    font-family: "Financier Display";
    font-size: 48rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1em;
}

.buttons-group {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.section2-content {
    position: relative;
    /* overflow: hidden; */
    z-index: 2;
}

.group-button-left {
    padding: 20rem 36rem;
    border-radius: 5rem 0 0 5rem;
    background: #d0d0d0;
    color: #6a6967;
    text-align: center;
    font-size: 24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    transition: all 0.3s;
}

.group-button-right {
    padding: 20rem 36rem;
    border-radius: 0px 5rem 5rem 0px;
    background: #d0d0d0;
    color: #6a6967;
    text-align: center;
    font-size: 24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    transition: all 0.3s;
}

.group-button-left:hover,
.group-button-right:hover {
    color: #6a6967;
}

.group-button-active:hover {
    color: #fff;
}

.group-button-active {
    background: #a52933;
    color: #fff;
}

.section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90rem 0 100rem 0;
    background: url(../assets/image/win/section3_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.section3-content {
    margin-top: 90rem;
    display: flex;
    flex-direction: column;
    min-width: 720rem;
    /* justify-content: center; */
}

.section3-content .p1 {
    color: #fff;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    opacity: 0.4;
}

.section3-group {
    display: flex;
    gap: 25rem;
    margin-top: 18rem;
}

.section3-group-card {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 137rem;
    flex-shrink: 0;
    border-radius: 5rem;
    overflow: hidden;
}

.section3-group-card1 {
    width: 375rem;
}

.section3-group-card2 {
    width: 318rem;
}

.section3-group-card-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #9f734c;
    padding: 10rem 16rem;
    color: #ffdaa0;
    text-align: center;
    font-size: 19rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22rem;
}

.section3-group-card-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #a52933;
    background: #f9f6f2;
    padding: 10rem 0;
    font-family: "Financier Display";
    font-size: 96rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    flex: 1;
    text-align: center;
}

.section3-group-card-right .unit {
    color: #a52933;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.section3-card {
    margin-top: 18rem;
    min-height: 189rem;
    padding: 0rem 48rem 20rem;
    border-radius: 5rem;
    background: #f9f6f2;
}

.section3-card-steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 28rem;
    margin-top: 16rem;
}

.section3-card-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.step-img {
    width: 154rem;
    height: 84rem;
}

.right-img {
    margin-top: 50rem;
    height: 60rem;
    width: 31rem;
}

.section3-card-step-value {
    margin-top: 10rem;
    color: #5d2333;
    font-size: 32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}

.en .section3-card-step-value {
    max-width: 154rem;
    font-size: 21rem;
    text-align: center;
    line-height: 1.2em;
}

.times {
    margin-top: 16rem;
    color: #b2afad;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 8rem;
}

.mt-12 {
    margin-top: 12rem;
}

.mt-46 {
    margin-top: 46rem;
}

.mt-56 {
    margin-top: 56rem;
}

.section4-table {
    margin: 32rem auto 0;
    width: 1380rem;
}

.no-data {
    width: 100%;
    height: 310rem;
    background-color: #f9f6f2;
    background-image: url(../assets/image/win/no-data.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 286rem;
    font-size: 18rem;
    color: #6a6967;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section4-table .tr,
.section4-table .th {
    display: flex;
    align-items: center;
    padding: 24rem 50rem;
    background: #f9f6f2;
}

.section4-table .tr:hover {
    background: #e6ddcf;
}

.section4-table .th {
    background: #5d2333;
}

.new-table .th {
    background: #81552e;
}

.section4-table .td {
    width: 14.28%;
    flex-shrink: 0;
}

.section4-table .td {
    width: 14.28%;
    flex-shrink: 0;
    flex-grow: 1;
}

.section4-table .td:first-child {
    width: 10%;
}



.section4-table .tr .td {
    color: #6a6967;
    text-align: center;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.section4-table .th .td {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button-text,
.button-text:hover {
    color: #9f734c;
}

.section4-table .tr>div:nth-child(5) {
    color: #980b31;
    font-weight: 500;
}

.tr+.tr {
    border-top: 1px solid #ddd;
}

.section4-tips {
    margin-top: 24rem;
    width: 1380rem;
    color: #fff;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.section5 {
    background: linear-gradient(180deg, #5d2333 0%, #2b0a0a 100%);
    padding: 90rem 0 100rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section5-bg {
    margin-top: 58rem;
    width: 100%;
    /* height: 395.5rem; */
}

.section5-time-line {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 347.5rem;
    background-image: url(../assets/image/win/section5.png);
    background-size: cover;
    margin-top: 52rem;
}

.section5-lines {
    margin: 0 180rem;
    width: calc(100% - 360rem);
    display: flex;
    justify-content: space-around;
    padding: 0 60rem;
}

.section5-line-item {
    width: 25%;
}

.section5-line-item .p1 {
    color: #e6ddcf;
    text-align: center;
    font-family: Gilroy;
    font-size: 24rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
}

.section5-line-item .p2 {
    margin-top: 124rem;
    margin-bottom: 32rem;
    color: #fff;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
}

.en .section5-line-item .p2 {
    font-size: 28rem;
}

.tab-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 68rem;
}

.section6-line {
    width: 1px;
    height: 50rem;
    background: #b2afad;
}

.section6-content {
    margin-top: 68rem;
}

.section6-table {
    width: 1032rem;
    border-spacing: 1px;
}

.section6-table th {
    padding: 26rem 30rem;
    background: #5d2333;
    color: #fff;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    text-align: center;
}

.section6-table td {
    padding: 26rem 30rem;
    background: #fff;
    color: #6a6967;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    vertical-align: middle;
}

.section6-table td:first-child {
    color: #000;
    font-size: 20rem;
    font-weight: 500;
}

.section6-table th:last-child {
    background: #a52933;
}

table {
    border-collapse: collapse;
    /* 合并边框 */
    border: none;
    /* 移除表格的外部边框 */
}

td,
th {
    text-align: center;
    border-bottom: 1px solid #ddd;
    /* 底边 */
    border-right: 1px solid #ddd;
    /* 右边 */
}

tr:last-child td {
    border-bottom: none;
    /* 移除最后一行的底边 */
}

tr th:last-child,
tr td:last-child {
    border-right: none;
    /* 移除每行最后一个单元格的右边 */
}

.section6-step-box {
    margin-top: 12rem;
    padding: 56rem 56rem 60rem 56rem;
    width: 1034rem;
    background: #fff;
}

.section6-step-title {
    color: #5d2333;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.64rem;
}

.section6-step-content {
    margin-top: 36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24rem;
}

.section6-step-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 260rem;
    height: 260rem;
}

.en .section6-step-item {
    padding: 0 30rem;
}

.section6-step-item h5 {
    color: #a52933;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}

.section6-step-item .p2 {
    color: #434141;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
}

.en .section6-step-item .p2 {
    line-height: normal;
}

.section6-item-1 {
    background: url(../assets/image/win/section6_step1.png);
    background-size: cover;
}

.section6-item-2 {
    background: url(../assets/image/win/section6_step2.png);
    background-size: cover;
}

.section6-item-3 {
    background: url(../assets/image/win/section6_step3.png);
    background-size: cover;
}

.section6-right {
    width: 23rem;
    height: 70rem;
}

.section6-list {
    padding: 42rem 32rem 52rem;
    width: 782rem;
    border-radius: 5rem;
    background: #fff;
}

.section6-list-title {
    color: #232222;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 0.2rem;
    margin-bottom: 18rem;
}

.section6-list-item {
    padding-bottom: 14rem;
    border-bottom: 1px solid #e5e5e5;
}

.section6-list-item+.section6-list-item {
    margin-top: 14rem;
}

.section6-list-item-title {
    color: #5d2333;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20rem;
}

.section6-list-item-content {
    margin-top: 10rem;
    color: #6d6d6d;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
}

.section6-list-item-content>p {
    position: relative;
    padding-left: 12rem;
}

.section6-list-item-content>p::before {
    position: absolute;
    left: 4rem;
    top: 11rem;
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #6d6d6d;
}

.qa-list {
    padding: 42rem 32rem 52rem;
    width: 782rem;
    border-radius: 5rem;
    background: #fff;
}

.qa-list-title {
    color: #232222;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 0.2rem;
    margin-bottom: 18rem;
}

.qa-list-item {
    padding-bottom: 14rem;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
}

.qa-list-item+.qa-list-item {
    margin-top: 14rem;
}

.qa-list-item-title {
    position: relative;
    color: #5d2333;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20rem;
    padding-right: 28rem;
}

.qa-list-item-title::after {
    position: absolute;
    right: 4rem;
    top: 0;
    font-family: "iconfont" !important;
    font-size: 18rem;
    content: "\e618";
    transform: rotate(90deg);
    transition: all 0.3s;
}

.active .qa-list-item-title::after {
    transform: rotate(-90deg);
}

.qa-list-item-content {
    margin-top: 10rem;
    color: #6d6d6d;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
}

.active .qa-list-item-content {
    height: auto;
}

.qa-list-item-content>p {
    position: relative;
    padding-left: 12rem;
}

.qa-list-item-content>p::before {
    position: absolute;
    left: 4rem;
    top: 11rem;
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #6d6d6d;
}

.button-title {
    color: #b2afad;
    text-align: center;
    font-size: 48rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.96rem;
    transition: all 0.3s;
    cursor: pointer;
}

.button-title-active {
    color: #5d2333;
}

.radius-5 {
    border-radius: 5rem;
}

.section7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90rem 0 100rem 0;
    background: url(../assets/image/win/section7_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.section8-content {
    display: flex;
    justify-content: center;
    gap: 20rem;
    margin-top: 68rem;
}

.section8-item {
    padding: 12rem 12rem 28rem 12rem;
    display: flex;
    flex-direction: column;
    gap: 24rem;
    border-radius: 5rem;
    background: #fff;
    cursor: pointer;
}

.section8-item-img {
    width: 380rem;
    height: 254rem;
}

.section8-item h4 {
    color: #232222;
    font-size: 24rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    text-align: center;
    width: 380rem;
}

.section6-step-box .buttons {
    margin-top: -14rem;
}

@media (max-width: 996px) {
    .hidden {
        display: block;
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    .section6-step-box .buttons {
        margin-top: 0;
    }

    .en .section6-step-box .buttons {
        margin-top: 16rem;
    }

    .en .tab-title,
    .en .button-title {
        font-size: 44rem;
    }

    .section1 {
        padding-left: 15rem;
        padding-right: 15rem;

        height: 1220rem;
        background: url("../assets/image/win/section1_bg_m.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .en .section1 {
        padding-bottom: 70rem;
    }

    .subtitle {
        margin-top: 70rem;
        font-size: 34rem;
    }



    .subtitle-line-left,
    .subtitle-line-right {
        width: 93rem;
    }

    .section1-info-time {
        flex-direction: column;
        margin-top: 50rem;
    }

    .section2f {
        padding: 100rem 0;
        background: url(../assets/image/win/section2f_bg_m.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .section2-card-value {
        margin-top: 4rem;
    }

    .section2f-card-box {
        flex-direction: column;
    }

    .section3-group-card1 {
        width: 339rem;
    }

    .section3-group-card2 {
        width: 294rem;
    }

    .section3-content {
        margin: 116rem 46rem 162rem;
        min-width: auto;
    }

    .section3 {
        background-position: 70%;
    }

    .section4-table {
        width: 700rem;
    }

    .section4-table .tr,
    .section4-table .th {
        padding: 24rem 6rem;
    }

    .section4-table .tr .td {
        font-size: 16rem;
    }

    .section4-tips {
        width: 700rem;
    }

    .section5-time-line {
        height: 296rem;
        background-image: url("../assets/image/win/section5_m.png");
        background-size: cover;
        margin-top: 90rem;
    }

    .section5-lines {
        width: 100%;
        margin: 0;
        padding: 0 78rem;
    }

    .section5-line-item .p2 {
        font-size: 24rem;
        margin-bottom: 24rem;
        margin-top: 110rem;
    }

    .en .section5-line-item .p2 {
        font-size: 16rem;
        margin-bottom: 24rem;
        margin-top: 90rem;
    }

    .section5-line-item .p1 {
        font-size: 20rem;
    }

    .section6-table,
    .section6-step-box,
    .section6-list,
    .qa-list {
        width: 720rem;
    }

    .section6-step-content {
        gap: 10rem;
    }

    .section6-step-box {
        padding: 52rem 28rem;
    }

    .section6-step-item {
        width: 190rem;
        height: 190rem;
    }

    .section6-right {
        width: 16rem;
        height: 51rem;
    }

    .mt-0 {
        margin-top: 0;
    }

    .section8 {
        display: block;
    }

    .section8-item {
        display: inline-flex;
        width: 404rem;
        min-height: 306rem;
    }

    .section8-list-box {
        margin: 67rem 135rem 0;
        padding: 0;
        overflow: hidden;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .section8-list .swiper-button-prev:after,
    .section8-list .swiper-rtl .swiper-button-next:after {
        content: "";
    }

    .section8-list .swiper-button-next:after,
    .section8-list .swiper-rtl .swiper-button-prev:after {
        content: "";
    }

    .swiper-news-prev {
        background-image: url("../assets/image/win/left.svg");
        width: 22rem !important;
        height: 44rem !important;
        background-size: cover;
        margin-left: 78rem;
    }

    .swiper-news-next {
        background-image: url("../assets/image/win/right.svg");
        width: 22rem !important;
        height: 44rem !important;
        background-size: cover;
        margin-right: 78rem;
    }

    .section2,
    .section3,
    .section4,
    .section5,
    .section6,
    .section7,
    .section8 {
        padding: 120rem 0;
    }

    .section2 {
        display: block;
        overflow: hidden;
    }

    .reward-list,
    .reward-list2 {
        position: relative;
        margin-top: 80rem;
    }



    .reward-item {
        display: flex;
        align-items: flex-end;
        width: 282rem;
        height: 442rem;
    }

    .reward-item-1 {
        background-image: url("../assets/image/win/jiangbei1.png");
        background-size: cover;
    }

    .reward-item-2 {
        background-image: url("../assets/image/win/jiangbei2.png");
        background-size: 100% auto;
    }

    .reward-item-3 {
        background-image: url("../assets/image/win/jiangbei3.png");
        background-size: 100% auto;
    }

    .reward-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12rem;
        width: 100%;
        padding: 0 36rem;
        height: 170rem !important;
    }

    .reward-item .p1 {
        color: #5d2333;
        text-align: center;
        font-size: 32rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
    }


    .reward-item .p2 {
        display: block;
        color: #5d2333;
        text-align: center;
        font-size: 21rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2em;
    }

    .en .reward-item .p2 {
        font-size: 18rem;
    }

    .section1-info-time-label {
        margin-right: 0;
    }

    .tab-btns {
        gap: 30rem;
    }

    .step-img {
        width: 140rem;
        height: auto;
    }

    .section3-card {
        padding: 0rem 20rem 20rem;
    }

    .en .reward-item .p1 {
        font-size: 23rem;
    }

    .section2f-card .p1 {
        font-size: 48rem;
    }

    .section2f-card .p2 {
        font-size: 22rem;
        line-height: normal;
    }

    .en .section2f-card .p1 {
        font-size: 46rem;
    }

    .en .section2f-card .p2 {
        font-size: 18rem;
        line-height: 1.2em;
    }

    .en .section2f-card {
        width: 448rem;
        background-size: 348rem;
        background-position: center;
        background-repeat: no-repeat;
    }

    .en .section6-step-item {
        gap: 14rem;
    }

    .en .section6-step-item {
        padding: 0;
    }
}
</style>